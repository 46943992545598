import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonStyle,
  PageHeading,
  Steps,
  DropdownListItem,
} from "@prequel-internal/react-components";

import BrandedLogo from "../../components/BrandedLogo";
import { ImportMagicLink } from "../../store/magic_link";
import SourceForm from "./SourceForm";
import QueryForm from "./QueryForm";
import MapForm from "./MapForm";
import ExpectedColumns from "./ExpectedColumns";
import { Source, ModelMapping, prepareSource } from "../../store/import_source";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  createImportSource,
  resetImportSourceTableSample,
  resetImportSourceTest,
  selectTableSample,
} from "../../store/import_source/import_source.duck";

const STEPS = ["Connect Source", "Query Source", "Map Source"];

type ConnectImportProps = {
  orgId: string;
  linkId: string;
  magicLink: ImportMagicLink;
  prodEnv: boolean;
};
const ConnectImport = ({
  magicLink,
  orgId,
  prodEnv,
  linkId,
}: ConnectImportProps) => {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const tableSample = useTypedSelector(selectTableSample);
  const [step, setStep] = useState<number>(0);
  const [canGoForward, setCanGoForward] = useState(false);
  const [source, setSource] = useState<Source>({
    vendor: magicLink.vendor,
    name: "",
    host: magicLink.host,
    port: "",
    database: "",
    schema: "",
    username: "",
    password: "",
    service_account_key: "",
    bucket_vendor: magicLink.bucket_vendor,
    bucket_name: magicLink.bucket_name,
    bucket_region: "",
    bucket_access_id: magicLink.bucket_access_id,
    bucket_secret_key: "",
    ssh_public_key: "",
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
    use_ssh_tunnel: false,
    model_mappings: [],
  });
  const [destinationToSourceMap, setDestinationToSourceMap] = useState<{
    [key: string]: DropdownListItem<string> | undefined;
  }>({});

  // Cleanup test state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetImportSourceTest());
      dispatch(resetImportSourceTableSample());
    };
  }, []);

  const goBack = () => {
    setStep(step - 1);
    setCanGoForward(false);
  };

  const goForward = () => {
    setStep(step + 1);
    setCanGoForward(false);
  };

  const submitImportSource = () => {
    const mapping: ModelMapping = {
      model_name: magicLink.config_to_map.model_name,
      source_table_name: tableSample?.table?.table_name ?? "",
      description: magicLink.config_to_map.description,
      mappings: magicLink.config_to_map.columns.map((column) => ({
        ...column,
        name_in_source:
          destinationToSourceMap[column.name_in_destination]?.key ?? "",
      })),
    };

    source.schema = tableSample?.table?.schema ?? "";
    source.model_mappings = [mapping];
    const preparedSource = prepareSource(source);
    dispatch(
      createImportSource({
        magiclinkargs: { orgId: orgId, linkId: linkId, isProd: prodEnv },
        source: preparedSource,
        redirect: () => navigate("success", { replace: false }),
      })
    );
  };

  return (
    <>
      <nav
        className="bg-white-100 bg-white border-b border-gray-200"
        aria-label="Global"
      >
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center px-2 lg:px-0">
              <div className="flex-shrink-0 inline-flex items-center justify-center w-36 rounded-lg">
                <BrandedLogo orgId={orgId} />
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-7xl mx-auto pt-10 pb-12 px-4 lg:pb-16">
        <div className="grid grid-cols-3">
          <div className="col-start-0 col-span-2">
            <PageHeading title={STEPS[step]} />
            <Steps currentStep={step} steps={STEPS} setStep={setStep} />
          </div>
        </div>
        <div className="pb-16 pt-6 grid grid-cols-3">
          <div className="col-start-0 col-span-2">
            {step === 0 && (
              <SourceForm
                magicLink={magicLink}
                linkId={linkId}
                orgId={orgId}
                isProd={prodEnv}
                source={source}
                setSource={setSource}
                canSubmit={setCanGoForward}
              />
            )}
            {step === 1 && (
              <QueryForm
                source={source}
                linkId={linkId}
                orgId={orgId}
                isProd={prodEnv}
                canSubmit={setCanGoForward}
              />
            )}
            {step === 2 && (
              <MapForm
                configToMap={magicLink.config_to_map}
                destinationToSourceMap={destinationToSourceMap}
                setDestinationToSourceMap={setDestinationToSourceMap}
                canSubmit={setCanGoForward}
              />
            )}
            <div className="flex mt-8">
              {step > 0 && (
                <Button
                  text="Back"
                  type={ButtonStyle.TERTIARY}
                  onClick={goBack}
                />
              )}
              {step === 2 ? (
                <Button
                  className="ml-auto"
                  type={
                    canGoForward ? ButtonStyle.PRIMARY : ButtonStyle.TERTIARY
                  }
                  onClick={submitImportSource}
                  text="Save Connection"
                  disabled={!canGoForward}
                />
              ) : (
                <Button
                  className="ml-auto"
                  type={
                    canGoForward ? ButtonStyle.PRIMARY : ButtonStyle.TERTIARY
                  }
                  onClick={goForward}
                  text="Continue"
                  disabled={!canGoForward}
                />
              )}
            </div>
          </div>

          <div className="col-start-3 col-span-1 ml-6">
            <ExpectedColumns config={magicLink.config_to_map} />
          </div>
        </div>
      </main>
    </>
  );
};

export default ConnectImport;
