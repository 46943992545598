import React, { useEffect } from "react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";

import { ReactComponent as AlertCircleIcon } from "../../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../../assets/icons/ellipsis.svg";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import { PreparedSource } from "../../../store/import_source";
import {
  resetImportSourceTest,
  selectImportSourceTest,
  testImportSource,
} from "../../../store/import_source/import_source.duck";

type TestImportSourceConnectionProps = {
  beforeSubmitTest?: () => boolean;
  source: PreparedSource;
  orgId: string;
  linkId: string;
  isProd: boolean;
};
const TestImportSourceConnection = ({
  beforeSubmitTest = () => true,
  source,
  orgId,
  linkId,
  isProd,
}: TestImportSourceConnectionProps) => {
  const dispatch = useTypedDispatch();
  const importSourceTest = useTypedSelector(selectImportSourceTest);

  useEffect(() => {
    dispatch(resetImportSourceTest());
  }, [source]);

  // Cleanup test state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetImportSourceTest());
    };
  }, []);

  const onTestConnection = () => {
    if (!beforeSubmitTest()) {
      return;
    }

    dispatch(
      testImportSource({
        magiclinkargs: {
          orgId,
          linkId,
          isProd,
        },
        source,
      })
    );
  };

  return (
    <div className="relative pt-4">
      <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
        <div className="p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <div
                className={`mx-auto flex items-center justify-center h-10 w-10 rounded-full
                  ${
                    importSourceTest.status === "success"
                      ? "bg-emerald-100"
                      : ""
                  }
                  ${importSourceTest.status === "error" ? "bg-red-100" : ""}
                  ${
                    importSourceTest.status === "processing"
                      ? "bg-gray-100"
                      : ""
                  }
                  ${!importSourceTest.status ? "bg-gray-100" : ""}`}
              >
                {importSourceTest.status === "success" && (
                  <CheckIcon
                    className="h-5 w-5 text-emerald-600"
                    aria-hidden="true"
                  />
                )}
                {importSourceTest.status === "error" && (
                  <AlertCircleIcon
                    className="h-5 w-5 text-red-600"
                    aria-hidden="true"
                  />
                )}
                {importSourceTest.status === "processing" && (
                  <EllipsisIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
                {!importSourceTest.status && (
                  <AlertTriangleIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            <div className="ml-3 mt-1">
              <h3 className="text-lg font-medium text-gray-800">
                {importSourceTest.status === "success" &&
                  "Connection successful!"}
                {importSourceTest.status === "error" &&
                  "Error connecting to destination."}
                {importSourceTest.status === "processing" &&
                  "Testing connection..."}
                {!importSourceTest.status && "Test connection before saving."}
              </h3>
              <div className="mt-2 mb-2 text-sm text-gray-700">
                <p>
                  {importSourceTest.status === "success" &&
                    "Connection was successfully established to this destination. The destination details can now be saved."}
                  {importSourceTest.status === "error" && (
                    <>
                      <span className="block mb-4">
                        Try updating the destination details and try again.
                      </span>
                      <code className="text-xs">
                        {importSourceTest.message}
                      </code>
                    </>
                  )}
                  {importSourceTest.status === "processing" && ""}
                  {!importSourceTest.status && ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Spacer element to match the height of the toolbar */}
        <div aria-hidden="true">
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 inset-x-px">
        <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <Button
                onClick={onTestConnection}
                type={ButtonStyle.PRIMARY}
                disabled={importSourceTest.status === "processing"}
                text={
                  importSourceTest.status === "processing" ? (
                    <div className="flex">
                      <Spinner.Inline className="mr-3 text-white" />
                      Testing Connection...
                    </div>
                  ) : (
                    "Test Connection"
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestImportSourceConnection;
