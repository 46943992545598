import { ModelConfig, BucketVendor } from "@prequel/react";
import { RadioCardOption } from "@prequel-internal/react-components";

export interface MagicLinkArgs {
  orgId: string;
  linkId: string;
  isProd: boolean;
}

export interface MagicLink {
  destination_name: string;
  host: string;
  bucket_name: string;
  vendor: string;
  created_at: string;
  has_been_used: boolean;
  available_models: string[];
  bucket_vendor: BucketVendor;
  bucket_access_id: string;
  ssh_public_key: string;
  source_docs_override?: string;
  destination_docs_override?: string;
  redirect_url?: string;
  org_default_frequency_minutes: number;
}

export interface ImportMagicLink {
  name: string;
  vendor: string;
  provider_id: string;
  host: string;
  bucket_name: string;
  bucket_access_id: string;
  bucket_vendor: string;
  ssh_public_key: string;
  created_at: string;
  has_been_used: boolean;
  config_to_map: ModelConfig;
  source_docs_override?: string;
  destination_docs_override?: string;
}

const frequencyOptions: RadioCardOption<number>[] = [
  {
    label: "15 m",
    value: 15,
    enabled: false,
  },
  {
    label: "1 hr",
    value: 60,
    enabled: true,
  },
  {
    label: "6 hr",
    value: 360,
    enabled: true,
  },
  {
    label: "12 hr",
    value: 720,
    enabled: true,
  },
  {
    label: "24 hr",
    value: 1440,
    enabled: true,
  },
];

export const getFrequencyOptions: (
  f: number | undefined
) => RadioCardOption<number>[] = (fastestAllowed) => {
  if (fastestAllowed === undefined) {
    return [];
  }

  return frequencyOptions.map((opt) => ({
    ...opt,
    enabled: opt.value >= fastestAllowed,
  }));
};

export const getFrequencyLabel: (f: number) => string | undefined = (
  frequency
) => {
  const opt = frequencyOptions.find(({ value }) => value === frequency);

  return opt ? opt.label : undefined;
};
