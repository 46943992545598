import React, { useEffect, useMemo, useRef } from "react";
import { FormField, TextArea } from "@prequel-internal/react-components";
import { VendorField } from "@prequel/react";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchImportSourceVendors,
  selectImportSourceTest,
  selectImportSourceVendors,
} from "../../../store/import_source/import_source.duck";
import { ImportMagicLink } from "../../../store/magic_link";
import {
  getValidServiceAccountKey,
  getVendorLogo,
} from "../../../store/destination";
import BaseForm from "../BaseForm";
import {
  PreparedSource,
  prepareSource,
  Source,
  Vendor,
} from "../../../store/import_source";
import SSHTunnel from "../SSHTunnel";
import TestImportSourceConnection from "../TestImportSourceConnection";

type SourceFormProps = {
  magicLink: ImportMagicLink;
  orgId: string;
  linkId: string;
  isProd: boolean;
  source: Source;
  setSource: React.Dispatch<React.SetStateAction<Source>>;
  canSubmit: React.Dispatch<React.SetStateAction<boolean>>;
};
const SourceForm = ({
  magicLink,
  orgId,
  linkId,
  isProd,
  canSubmit,
  source,
  setSource,
}: SourceFormProps) => {
  const dispatch = useTypedDispatch();
  const sourceVendors: Vendor[] | undefined = useTypedSelector(
    selectImportSourceVendors
  );
  const importSourceTest = useTypedSelector(selectImportSourceTest);
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    dispatch(fetchImportSourceVendors());
  }, [dispatch]);

  useEffect(() => {
    canSubmit(importSourceTest.status === "success");
  }, [importSourceTest]);

  const sourceVendor = useMemo(
    () =>
      sourceVendors?.find(
        ({ vendor_name }) => vendor_name === magicLink.vendor
      ),
    [sourceVendors]
  );
  const VendorLogo = useMemo(
    () => getVendorLogo(sourceVendor?.vendor_name || ""),
    [sourceVendor]
  );

  // convert into map of name -> object
  const formFields: { [key: string]: VendorField } = useMemo(() => {
    if (!sourceVendor) {
      return {};
    }

    return sourceVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [sourceVendor]);

  const setSourceField = (key: string, value: string | boolean) => {
    setSource((oldSource) => ({
      ...oldSource,
      [key]: value,
    }));
  };

  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!getValidServiceAccountKey(source.service_account_key),
    [source.service_account_key]
  );

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  const preparedSource: PreparedSource = useMemo(
    () => prepareSource(source),
    [source]
  );

  // Intercept native form submission, prevent default
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <form
      ref={formRef}
      onSubmit={onSubmit}
      className="space-y-8 bg-white border border-gray-200 rounded-lg px-5 py-6"
    >
      <div className="space-y-4">
        <div>
          <label
            htmlFor="destination-type"
            className="block text-sm font-medium text-gray-700"
          >
            Source Type
          </label>
          <div className="flex pt-2">
            <VendorLogo className="flex-shrink-0 h-6 w-6 rounded-full mr-3" />
            {sourceVendor?.display_name}
          </div>
        </div>
      </div>
      <div className="space-y-4">
        {sourceVendor && (
          <BaseForm
            docs={magicLink.source_docs_override}
            vendor={sourceVendor}
          />
        )}
        {"host" in formFields && (
          <FormField
            id="host"
            type="text"
            label={formFields.host.label}
            placeholder={formFields.host.placeholder}
            subtext={formFields.host.help}
            value={source.host}
            onChangeHandler={(value: string) => {
              setSourceField("host", value);
            }}
            required={formFields.host.is_required}
            disabled
          />
        )}
        {"port" in formFields && (
          <FormField
            id="port"
            type="text"
            label={formFields.port.label}
            placeholder={formFields.port.placeholder}
            subtext={formFields.port.help}
            value={source.port}
            onChangeHandler={(value: string) => {
              setSourceField("port", value);
            }}
            required={formFields.port.is_required}
            disabled={importSourceTest.status === "processing"}
          />
        )}
        {"database" in formFields && (
          <FormField
            id="database"
            type="text"
            label={formFields.database.label}
            placeholder={formFields.database.placeholder}
            subtext={formFields.database.help}
            value={source.database}
            onChangeHandler={(value: string) => {
              setSourceField("database", value);
            }}
            required={formFields.database.is_required}
            disabled={importSourceTest.status === "processing"}
          />
        )}
      </div>
      <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
      <div className="space-y-4">
        {sourceVendor && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Enter the source credentials
            </label>
            <div className="mt-1">
              <p className="mt-1 text-sm text-gray-500">
                {`Provide the details shared by the owner of the ${sourceVendor.display_name} source in the form below. For assistance, `}
                <a
                  href={magicLink.source_docs_override || sourceVendor.docs}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  view our documentation on {sourceVendor.display_name}.
                </a>
              </p>
            </div>
          </div>
        )}
        {"username" in formFields && (
          <FormField
            id="username"
            type="text"
            label={formFields.username.label}
            placeholder={formFields.username.placeholder}
            subtext={formFields.username.help}
            value={source.username}
            onChangeHandler={(value: string) => {
              setSourceField("username", value);
            }}
            required={formFields.username.is_required}
            disabled={importSourceTest.status === "processing"}
          />
        )}
        {"password" in formFields && (
          <FormField
            id="password"
            type="password"
            label={formFields.password.label}
            placeholder={formFields.password.placeholder}
            subtext={formFields.password.help}
            value={source.password}
            onChangeHandler={(value: string) => {
              setSourceField("password", value);
            }}
            required={formFields.password.is_required}
            disabled={importSourceTest.status === "processing"}
          />
        )}
        {"service_account_key" in formFields && (
          <TextArea
            id="service_account_key"
            placeholder={formFields.service_account_key.placeholder}
            subtext={formFields.service_account_key.help}
            value={source.service_account_key}
            onChangeHandler={(value: string) => {
              setSourceField("service_account_key", value);
            }}
            invalid={!tokenIsValid}
            required={formFields.service_account_key.is_required}
            disabled={importSourceTest.status === "processing"}
          />
        )}
        {sourceVendor?.uses_staging_bucket && (
          <div className="h-px w-full bg-gray-200"></div>
        )}
        <div className="space-y-4">
          {sourceVendor?.uses_staging_bucket && (
            <>
              <label className="block text-sm font-medium text-gray-700">
                Enter the staging bucket credentials
              </label>
              <div className="mt-1">
                <p className="mt-1 text-sm text-gray-500">
                  {
                    "For some sources without built in data staging areas, a staging bucket must be provided for efficent data loading. For assistance, "
                  }
                  <a
                    href={magicLink.source_docs_override || sourceVendor.docs}
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    view our documentation on staging buckets.
                  </a>
                </p>
              </div>
            </>
          )}
          {"bucket_vendor" in formFields && (
            <FormField
              id="bucket_vendor"
              type="text"
              label={formFields.bucket_vendor.label}
              placeholder={formFields.bucket_vendor.placeholder}
              subtext={formFields.bucket_vendor.help}
              value={source.bucket_vendor}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_vendor", value);
              }}
              required={formFields.bucket_vendor.is_required}
              disabled
            />
          )}
          {"bucket_name" in formFields && (
            <FormField
              id="bucket_name"
              type="text"
              label={formFields.bucket_name.label}
              placeholder={formFields.bucket_name.placeholder}
              subtext={formFields.bucket_name.help}
              value={source.bucket_name}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_name", value);
              }}
              required={formFields.bucket_name.is_required}
              disabled
            />
          )}
          {"bucket_region" in formFields && (
            <FormField
              id="bucket_region"
              type="text"
              label={formFields.bucket_region.label}
              placeholder={formFields.bucket_region.placeholder}
              subtext={formFields.bucket_region.help}
              value={source.bucket_region}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_region", value);
              }}
              required={formFields.bucket_region.is_required}
              disabled={importSourceTest.status === "processing"}
            />
          )}
          {"bucket_access_id" in formFields && (
            <FormField
              id="bucket_access_id"
              type="text"
              label={formFields.bucket_access_id.label}
              placeholder={formFields.bucket_access_id.placeholder}
              subtext={formFields.bucket_access_id.help}
              value={source.bucket_access_id}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_access_id", value);
              }}
              required={formFields.bucket_access_id.is_required}
              disabled={
                magicLink.vendor === "abs" || magicLink.bucket_vendor === "abs"
              }
            />
          )}
          {"bucket_secret_key" in formFields && (
            <FormField
              id="bucket_secret_key"
              type="password"
              label={formFields.bucket_secret_key.label}
              placeholder={formFields.bucket_secret_key.placeholder}
              subtext={formFields.bucket_secret_key.help}
              value={source.bucket_secret_key}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_secret_key", value);
              }}
              required={formFields.bucket_secret_key.is_required}
              disabled={importSourceTest.status === "processing"}
            />
          )}
        </div>
        {sourceVendor?.supports_ssh_tunnel && (
          <SSHTunnel
            fields={source}
            setField={setSourceField}
            publicKey={magicLink.ssh_public_key}
          />
        )}
      </div>
      <TestImportSourceConnection
        beforeSubmitTest={validateForm}
        source={preparedSource}
        orgId={orgId}
        linkId={linkId}
        isProd={isProd}
      />
    </form>
  );
};

export default SourceForm;
