import React from "react";
import { Badge, BadgeColor } from "@prequel-internal/react-components";
import { TableSample } from "../../../store/import_source";

type DataPreviewProps = {
  rows: TableSample;
};
const DataPreview = ({ rows }: DataPreviewProps) => {
  const columns = rows.length >= 1 ? Object.keys(rows[0]) : [];

  return (
    <>
      <div className="overflow-hidden rounded-lg border border-gray-200 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
        <div
          className="block w-full resize-none border-0 p-4 text-gray-900 text-sm sm:leading-6"
          placeholder="Write a description..."
          defaultValue={""}
        >
          <div className="flex-1">
            <p className="text-sm font-medium text-gray-500">
              Detected Columns{" "}
            </p>

            <dd className="text-sm text-gray-900 mt-2 space-y-2">
              {columns.map((column) => (
                <Badge
                  key={column}
                  className="mr-2"
                  //   className="font-mono bg-emerald-50 px-2 py-1 border rounded-xl border-emerald-500"
                  text={column}
                  color={BadgeColor.GREEN}
                />
              ))}
            </dd>
          </div>
        </div>

        {/* Spacer element to match the height of the toolbar */}
        {/* <div aria-hidden="true">
          <div className="py-2">
            <div className="h-2" />
          </div>
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div> */}
      </div>
      {/* <div className="absolute inset-x-px bottom-0">
        <div className="flex flex-nowrap justify-end space-x-2 py-2 px-2 sm:px-3">
          <div className="flex-shrink-0" />

          <div className="flex-shrink-0" />
        </div>
        <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3">
          <div className="flex">
            <button
              type="button"
              className="group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400"
            >
              <span className="text-sm italic text-gray-500 group-hover:text-gray-600">
                Preview data
              </span>
            </button>
          </div>
          <div className="flex-shrink-0">
            <Button text="Edit Query" type="tertiary" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default DataPreview;
