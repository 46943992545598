import React from "react";
import { CopyToClipboard } from "@prequel-internal/react-components";

type SSHPublicKeyProps = {
  publicKey: string;
};
const SSHPublicKey = ({ publicKey }: SSHPublicKeyProps) => {
  if (!publicKey) {
    return <></>;
  }

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">
        SSH Public Key
      </label>
      <div className="w-full shadow-sm inline-flex rounded-md pl-3 pr-1 py-2 mt-1 text-gray-500 text-sm bg-gray-50 border border-gray-300 font-mono">
        <div className="w-full truncate mr-3">{publicKey}</div>
        <CopyToClipboard textToCopy={publicKey} />
      </div>
      <p className="mt-2 text-xs text-gray-400">
        {`This is the public SSH key we'll use to establish the SSH tunnel. 
           Please add it to the list of authorized keys on your bastion host `}
        <a
          href="https://docs.prequel.co/docs/ssh-tunneling"
          target="_blank"
          rel="noreferrer"
          className="font-medium text-primary-600 hover:text-primary-500"
        >
          (see documentation).
        </a>
      </p>
    </div>
  );
};

export default SSHPublicKey;
