import { MagicLinkArgs } from "../magic_link";
import { AvailableTable, PreparedSource, TableSample, Vendor } from ".";
import axios, { ApiResponse, AppError, ErrorResponse } from "../../axios";
import { WithRedirect } from "..";

const getVendors: () => Promise<Vendor[]> = () => {
  return axios
    .get("/public/vendors/sources")
    .then(
      (response: ApiResponse<{ sources: Vendor[] }>) =>
        response.data.data.sources
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message: reason.response?.data?.message || "Failed to get vendors.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postTestSource: ({
  magiclinkargs,
  source,
}: {
  magiclinkargs: MagicLinkArgs;
  source: PreparedSource;
}) => Promise<void> = ({ magiclinkargs, source }) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/import/magic-links/${magiclinkargs.linkId}/test-connection`,
      {
        source,
      }
    )
    .then((response: ApiResponse<void>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postCreateSource: (
  payload: WithRedirect<{
    magiclinkargs: MagicLinkArgs;
    source: PreparedSource;
  }>
) => Promise<{}> = ({ magiclinkargs, source, redirect }) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/import/magic-links/${magiclinkargs.linkId}/convert`,
      {
        source,
      }
    )
    .then(() => ({
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message: reason.response?.data?.message || "Source creation failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getSourceTables: ({
  magiclinkargs,
  source,
}: {
  magiclinkargs: MagicLinkArgs;
  source: PreparedSource;
}) => Promise<AvailableTable[]> = ({ magiclinkargs, source }) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/import/magic-links/${magiclinkargs.linkId}/list-source-tables`,
      {
        source,
      }
    )
    .then(
      (response: ApiResponse<{ tables: AvailableTable[] }>) =>
        response.data.data.tables
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Failed to get source tables.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSampleTable: ({
  magiclinkargs,
  source,
  availableTable,
}: {
  magiclinkargs: MagicLinkArgs;
  source: PreparedSource;
  availableTable: AvailableTable;
}) => Promise<{ sample: TableSample; table: AvailableTable }> = ({
  magiclinkargs,
  source,
  availableTable,
}) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/import/magic-links/${magiclinkargs.linkId}/sample-table`,
      {
        source,
        schema: availableTable.schema,
        table: availableTable.table_name,
      }
    )
    .then((response: ApiResponse<{ rows: TableSample }>) => ({
      sample: response.data.data.rows,
      table: availableTable,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Failed to sample source table.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

export default {
  getVendors,
  postTestSource,
  postCreateSource,
  getSourceTables,
  postSampleTable,
};
