import React from "react";
import { ModelConfig } from "@prequel/react";
import { Badge } from "@prequel-internal/react-components";

import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";

type ExpectedColumnsProps = {
  config: ModelConfig;
};
const ExpectedColumns = ({ config }: ExpectedColumnsProps) => {
  return (
    <section aria-labelledby="timeline-title">
      <div className="bg-white border border-gray-200 sm:rounded-lg">
        <div className="py-5 px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Expected Columns
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Lookup table of expected columns.
          </p>
        </div>

        <div className="border-t border-gray-200 px-4 py-5 ">
          <ul role="list" className="">
            {config.columns.map((column) => {
              return (
                <li key={`${config.model_name}-${column.name_in_destination}`}>
                  <div className="relative pb-8">
                    <div className="relative flex space-x-3">
                      <div></div>
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div>
                          <div className="text-sm font-medium text-gray-900 flex items-center pb-0.5">
                            {column.name_in_destination}
                            {column.is_primary_key && (
                              <KeyIcon className="ml-2 text-emerald-500 w-4 h-4 align-center" />
                            )}
                            {column.is_last_modified && (
                              <ClockIcon className="ml-2 text-emerald-500 w-4 h-4" />
                            )}
                          </div>
                          <p className="text-sm text-gray-500">
                            {column.description}
                          </p>
                        </div>
                        <div className="whitespace-nowrap text-right text-sm italic text-gray-400">
                          {column.data_type}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ExpectedColumns;
