import { useSelector, TypedUseSelectorHook, useDispatch } from "react-redux"; // Redux
import { all, call, put } from "redux-saga/effects";
import createSagaMiddleware from "redux-saga";
import {
  configureStore,
  combineReducers,
  ActionCreatorWithPayload,
  ActionCreatorWithoutPayload,
} from "@reduxjs/toolkit";
import { AppError, injectStore } from "../axios";

import magicLinkReducer, { magicLinkSagas } from "./magic_link/magic_link.duck";
import destinationReducer, {
  destinationSagas,
} from "./destination/destination.duck";
import alertsReducer from "./alerts/alerts.duck";
import importSourceReducer, {
  importSourceSagas,
} from "./import_source/import_source.duck";

// R = Request Payload, S = Success Payload
type ApiCall<R, S> = (payload: R) => Promise<S>;
export const createWorkerSaga = <R, S>(
  request:
    | ActionCreatorWithPayload<R, string>
    | ActionCreatorWithoutPayload<string>,
  success: ActionCreatorWithPayload<S, string>,
  failure: ActionCreatorWithPayload<AppError, string>,
  api: ApiCall<R, S>
) => {
  return function* saga({ payload }: { type: string; payload: R }) {
    try {
      const res: S = yield call(api, payload);
      yield put(success(res));
    } catch (error: any) {
      const e: AppError = error;
      yield put(failure(e));
    }
  };
};

type RedirectPayload = { redirect: () => void };
export type WithRedirect<T> = T & RedirectPayload;
export const createRedirectSaga = () => {
  return function* saga({
    payload,
  }: {
    type: string;
    payload: RedirectPayload;
  }) {
    yield payload.redirect();
  };
};

const rootReducer = combineReducers({
  alerts: alertsReducer,
  destination: destinationReducer,
  importSource: importSourceReducer,
  magicLink: magicLinkReducer,
});

function* rootSaga() {
  yield all([destinationSagas(), importSourceSagas(), magicLinkSagas()]);
}

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        // Overrides the serializable rule of the payload.redirect field
        ignoredActionPaths: ["payload.redirect"],
      },
    }).concat([sagaMiddleware]),
  devTools: true,
});
sagaMiddleware.run(rootSaga);
// inject redux store into axios
injectStore(store);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
