import React from "react";
import { useLocation, useParams } from "react-router-dom";
import BrandedLogo from "../../components/BrandedLogo";

import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { Domain } from "../../store/destination";

const REDIRECT_SECONDS = 5;
type LocationState = { redirectUrl: string | undefined };
const Success = () => {
  const { orgId, domain } = useParams();
  const location = useLocation();
  // useLocation doesn't provide generic typing so we can define it as such here
  const state = location.state as LocationState;

  if (state?.redirectUrl) {
    // need to use ?? "" here for type complaints even though the empty string will never get sent because of the if check
    setTimeout(
      () => window.location.replace(state.redirectUrl ?? ""),
      REDIRECT_SECONDS * 1000
    );
  }

  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <span className="sr-only">Success!</span>
            <div className="flex-shrink-0 inline-flex items-center justify-center w-48 rounded-lg">
              <BrandedLogo orgId={orgId} />
            </div>
          </div>
          <div className="py-20">
            <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-emerald-100">
              <CheckIcon
                className="h-12 w-12 text-emerald-600"
                aria-hidden="true"
              />
            </div>
            <div className="text-center">
              <h1 className="mt-4 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Success!
              </h1>
              <p className="mt-2 text-base text-gray-500">
                {`You have successfully submitted your ${
                  domain === Domain.IMPORT ? "source" : "destination"
                }.`}
              </p>
              {state?.redirectUrl && (
                <p className="mt-2 text-sm text-gray-500">
                  You will be redirected in {REDIRECT_SECONDS} seconds. Click{" "}
                  <span className="text-emerald-500 hover:text-emerald-700">
                    <a href={state?.redirectUrl}>here</a>
                  </span>{" "}
                  to redirect now.
                </p>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Success;
