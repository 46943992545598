import React, { useEffect, useState } from "react";
import { ModelConfig, ModelConfigColumn } from "@prequel/react";
import { Dropdown, DropdownListItem } from "@prequel-internal/react-components";

import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";

import { useTypedSelector } from "../../../store";
import { selectTableSample } from "../../../store/import_source/import_source.duck";
import DataPreview from "../DataPreview";

type MapFormProps = {
  configToMap: ModelConfig;
  destinationToSourceMap: {
    [key: string]: DropdownListItem<string> | undefined;
  };
  setDestinationToSourceMap: React.Dispatch<
    React.SetStateAction<{
      [key: string]: DropdownListItem<string> | undefined;
    }>
  >;
  canSubmit: React.Dispatch<React.SetStateAction<boolean>>;
};
const MapForm = ({
  configToMap,
  destinationToSourceMap,
  setDestinationToSourceMap,
  canSubmit,
}: MapFormProps) => {
  const tableSample = useTypedSelector(selectTableSample);
  const [dropdownItems, setDropdownItems] =
    useState<DropdownListItem<string>[]>();

  useEffect(() => {
    // Sets up the map to be { name_in_destination: "" } so we can set the values in the dropdowns
    const newMap = configToMap.columns.reduce(
      (
        acc: { [key: string]: DropdownListItem<string> | undefined },
        obj: ModelConfigColumn
      ) => ({
        ...acc,
        [obj.name_in_destination]: undefined,
      }),
      {}
    );
    setDestinationToSourceMap(newMap);
  }, [configToMap]);

  useEffect(() => {
    if (tableSample.status === "success" && tableSample.rows) {
      const columns = Object.keys(tableSample.rows[0]);
      const items = columns.map((col) => ({
        key: col,
        text: col,
      }));
      setDropdownItems(items);
    }
  }, [tableSample]);

  useEffect(() => {
    const allAssigned = Object.values(destinationToSourceMap).every(
      (sourceItem) => sourceItem !== undefined
    );

    canSubmit(allAssigned && Object.values(destinationToSourceMap).length > 0);
  }, [destinationToSourceMap]);

  const setDestinationToSourceValue = (
    destinationName: string,
    sourceName: DropdownListItem<string>
  ) => {
    setDestinationToSourceMap((oldMap) => ({
      ...oldMap,
      [destinationName]: sourceName,
    }));
  };

  return (
    <section
      className="col-start-2 col-span-1"
      aria-labelledby="applicant-information-title"
    >
      <form
        // onSubmit={onSubmit}
        className="bg-white border border-gray-200 rounded-lg"
      >
        <div className="px-4 py-5">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Map Data Columns
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Assign data columns to expected schema.
          </p>
        </div>
        <div className="space-y-4 border-t border-gray-200 p-4">
          <div>
            <label className="text-base font-medium text-gray-900">
              Source table
            </label>
            {tableSample.status === "success" && tableSample.rows && (
              <DataPreview rows={tableSample.rows} />
            )}
          </div>
          <div>
            <label className="text-base font-medium text-gray-900">
              Mapped Columns
            </label>
            <dd className="mt-1.5 text-sm text-gray-900">
              <ul
                role="list"
                className="divide-y divide-gray-200 rounded-md border border-gray-200"
              >
                {configToMap.columns.map((column) => (
                  <li
                    key={`${configToMap.model_name}-${column.name_in_destination}`}
                    className="grid grid-cols-2 gap-x-4 p-3"
                  >
                    <div className="col-span-1">
                      <dd className="mt-1 text-sm text-gray-900 mt-2 truncate">
                        <div className="font-mono bg-gray-100 mr-1 px-1.5 border rounded-md border-gray-200 inline-flex items-center">
                          {column.name_in_destination}
                          {column.is_primary_key && (
                            <KeyIcon className="ml-2 text-emerald-500 w-4 h-4" />
                          )}
                          {column.is_last_modified && (
                            <ClockIcon className="ml-2 text-emerald-500 w-4 h-4" />
                          )}
                        </div>
                        <span className="font-mono text-zinc-400 dark:text-zinc-500">
                          {column.data_type}
                        </span>
                      </dd>
                    </div>
                    <div className="col-span-1">
                      {dropdownItems && (
                        <Dropdown
                          items={dropdownItems}
                          selectedItem={
                            destinationToSourceMap[column.name_in_destination]
                          }
                          setSelectedItem={(item: DropdownListItem<string>) =>
                            setDestinationToSourceValue(
                              column.name_in_destination,
                              item
                            )
                          }
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </dd>
          </div>
        </div>
      </form>
    </section>
  );
};

export default MapForm;
