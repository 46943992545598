import React, { useEffect, useState } from "react";
import { Destination, FormField } from "@prequel/react";
import {
  Checkbox,
  RadioButtons,
  RadioCardOption,
  RadioCards,
  Toggle,
} from "@prequel-internal/react-components";
import VendorLogo from "../../VendorLogo";
import {
  getFrequencyLabel,
  getFrequencyOptions,
} from "../../../store/magic_link";

type RadioWrapperProps = {
  field: FormField & { form_element: "radio" };
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  orgDefaultFrequencyMinutes: number;
  disabled: boolean;
};
const RadioWrapper = ({
  field,
  destination,
  setDestinationField,
  orgDefaultFrequencyMinutes,
  disabled,
}: RadioWrapperProps) => {
  if (field.type === "boolean") {
    return (
      <div key={field.name}>
        <Toggle
          label={field.label}
          enabled={!!destination[field.name]}
          setEnabled={(e: boolean) => setDestinationField(field.name, e)}
        />
      </div>
    );
  } else if (
    field.name === "auth_method" ||
    field.name === "bucket_auth_method"
  ) {
    const items: RadioCardOption<string>[] = field.enum.map((e) => ({
      value: e.key.toString(),
      label: e.display,
      enabled: true,
      icon: e.icon_url ? <VendorLogo logo_url={e.icon_url} /> : <></>,
    }));
    const selected = items.find(
      ({ value }) => value === destination[field.name]
    );

    return (
      <RadioCards
        size="md"
        label={field.label}
        options={items}
        selectedOption={selected}
        setSelectedOption={(opt) => setDestinationField(field.name, opt.value)}
        disabled={disabled}
      />
    );
  } else if (field.name === "frequency_minutes") {
    const frequencyOptions = getFrequencyOptions(0);
    return (
      <>
        <div className="flex items-center justify-between">
          <h2 className={"text-sm font-medium text-gray-900 mb-3"}>
            Sync Frequency
          </h2>
        </div>
        <Checkbox
          key="use_default_frequency"
          id="use_default_frequency"
          label={
            <span>
              Use default frequency
              <span className="text-gray-400 font-normal">
                {" "}
                ({getFrequencyLabel(orgDefaultFrequencyMinutes)})
              </span>
            </span>
          }
          checked={destination.frequency_minutes === "0"}
          setChecked={(isChecked: boolean) =>
            isChecked
              ? setDestinationField("frequency_minutes", "0")
              : setDestinationField(
                  "frequency_minutes",
                  orgDefaultFrequencyMinutes.toString() ?? "1440"
                )
          }
          disabled={disabled}
        />
        <RadioCards
          options={frequencyOptions}
          selectedOption={frequencyOptions.find(
            ({ value }) => value.toString() === destination.frequency_minutes
          )}
          setSelectedOption={({ value }) =>
            setDestinationField("frequency_minutes", value.toString())
          }
          disabled={disabled || destination.frequency_minutes === "0"}
        />
      </>
    );
  } else {
    const items = field.enum.map((e) => ({
      key: e.key.toString(),
      name: e.display,
      content: e.key,
    }));
    const selected = items.find(({ key }) => key === destination[field.name]);

    return (
      <div key={field.name}>
        <RadioButtons
          label={field.label}
          options={items}
          selectedOption={selected}
          setSelectedOption={(selected) =>
            setDestinationField(field.name, selected.key)
          }
          disabled={disabled}
        />
      </div>
    );
  }
};

export default RadioWrapper;
