import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";

import { AvailableTable, PreparedSource, TableSample, Vendor } from ".";
import {
  createRedirectSaga,
  createWorkerSaga,
  RootState,
  WithRedirect,
} from "..";
import { AppError } from "../../axios";
import { MagicLinkArgs } from "../magic_link";
import ImportSourceService from "./import_source.service";

type ImportSourceState = {
  vendors: Vendor[] | undefined;
  sourceCreate: { status: undefined } | { status: "processing" };
  sourceTest:
    | { status: undefined }
    | { status: "processing" }
    | { status: "success" }
    | { status: "error"; message: string };
  availableTables: {
    status: undefined | "processing" | "success" | "error";
    tables: AvailableTable[] | undefined;
  };
  tableSample: {
    status: undefined | "processing" | "success" | "error";
    table: AvailableTable | undefined;
    rows: TableSample | undefined;
  };
};
const initialState: ImportSourceState = {
  vendors: undefined,
  sourceTest: { status: undefined },
  sourceCreate: { status: undefined },
  availableTables: { status: undefined, tables: undefined },
  tableSample: { status: undefined, table: undefined, rows: undefined },
};

const fetchImportSourceVendorsReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<void>
> = (state) => {
  state;
};

const fetchImportSourceVendorsSuccessReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<Vendor[]>
> = (state, action) => {
  state.vendors = action.payload;
};

const fetchImportSourceVendorsFailureReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<AppError>
> = (state) => {
  state;
};

const testImportSourceReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<{ magiclinkargs: MagicLinkArgs; source: PreparedSource }>
> = (state) => {
  state.sourceTest = { status: "processing" };
};

const testImportSourceSuccessReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<void>
> = (state) => {
  state.sourceTest = { status: "success" };
};

const testImportSourceFailureReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<AppError>
> = (state, action) => {
  state.sourceTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const createImportSourceReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<
    WithRedirect<{
      magiclinkargs: MagicLinkArgs;
      source: PreparedSource;
    }>
  >
> = (state) => {
  state.sourceCreate = { status: "processing" };
};

const createImportSourceSuccessReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<{}>
> = (state) => {
  state.sourceCreate = { status: undefined };
};

const createImportSourceFailureReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<AppError>
> = (state) => {
  state.sourceCreate = { status: undefined };
};

const fetchImportSourceTablesReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<{ magiclinkargs: MagicLinkArgs; source: PreparedSource }>
> = (state) => {
  state.availableTables = { status: "processing", tables: undefined };
};

const fetchImportSourceTablesSuccessReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<AvailableTable[]>
> = (state, action) => {
  state.availableTables = { status: "success", tables: action.payload };
};

const fetchImportSourceTablesFailureReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<AppError>
> = (state) => {
  state.availableTables = { status: "error", tables: undefined };
};

const fetchImportSourceTableSampleReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<{
    magiclinkargs: MagicLinkArgs;
    source: PreparedSource;
    availableTable: AvailableTable;
  }>
> = (state) => {
  state.tableSample = {
    status: "processing",
    table: undefined,
    rows: undefined,
  };
};

const fetchImportSourceTableSampleSuccessReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<{ sample: TableSample; table: AvailableTable }>
> = (state, action) => {
  state.tableSample = {
    status: "success",
    table: action.payload.table,
    rows: action.payload.sample,
  };
};

const fetchImportSourceTableSampleFailureReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<AppError>
> = (state) => {
  state.tableSample = { status: "error", table: undefined, rows: undefined };
};

const resetImportSourceTestReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<void>
> = (state) => {
  state.sourceTest = { status: undefined };
};

const resetImportSourceTableSampleReducer: CaseReducer<
  ImportSourceState,
  PayloadAction<void>
> = (state) => {
  state.tableSample = { status: undefined, table: undefined, rows: undefined };
};

function* watchFetchImportSourceVendors() {
  yield takeEvery(
    fetchImportSourceVendors.type,
    createWorkerSaga(
      fetchImportSourceVendors,
      fetchImportSourceVendorsSuccess,
      fetchImportSourceVendorsFailure,
      ImportSourceService.getVendors
    )
  );
}

function* watchTestImportSource() {
  yield takeEvery(
    testImportSource.type,
    createWorkerSaga(
      testImportSource,
      testImportSourceSuccess,
      testImportSourceFailure,
      ImportSourceService.postTestSource
    )
  );
}

function* watchCreateImportSource() {
  yield takeEvery(
    createImportSource.type,
    createWorkerSaga(
      createImportSource,
      createImportSourceSuccess,
      createImportSourceFailure,
      ImportSourceService.postCreateSource
    )
  );
}

function* watchFetchAvailableTables() {
  yield takeEvery(
    fetchImportSourceTables.type,
    createWorkerSaga(
      fetchImportSourceTables,
      fetchImportSourceTablesSuccess,
      fetchImportSourceTablesFailure,
      ImportSourceService.getSourceTables
    )
  );
}

function* watchFetchImportSourceTableSample() {
  yield takeEvery(
    fetchImportSourceTableSample.type,
    createWorkerSaga(
      fetchImportSourceTableSample,
      fetchImportSourceTableSampleSuccess,
      fetchImportSourceTableSampleFailure,
      ImportSourceService.postSampleTable
    )
  );
}

function* watchCreateImportSourceSuccess() {
  yield takeEvery(createImportSourceSuccess.type, createRedirectSaga());
}

const importSourceSlice = createSlice({
  name: "importSource",
  initialState,
  reducers: {
    fetchImportSourceVendors: fetchImportSourceVendorsReducer,
    fetchImportSourceVendorsSuccess: fetchImportSourceVendorsSuccessReducer,
    fetchImportSourceVendorsFailure: fetchImportSourceVendorsFailureReducer,
    testImportSource: testImportSourceReducer,
    testImportSourceSuccess: testImportSourceSuccessReducer,
    testImportSourceFailure: testImportSourceFailureReducer,
    createImportSource: createImportSourceReducer,
    createImportSourceSuccess: createImportSourceSuccessReducer,
    createImportSourceFailure: createImportSourceFailureReducer,
    fetchImportSourceTables: fetchImportSourceTablesReducer,
    fetchImportSourceTablesSuccess: fetchImportSourceTablesSuccessReducer,
    fetchImportSourceTablesFailure: fetchImportSourceTablesFailureReducer,
    fetchImportSourceTableSample: fetchImportSourceTableSampleReducer,
    fetchImportSourceTableSampleSuccess:
      fetchImportSourceTableSampleSuccessReducer,
    fetchImportSourceTableSampleFailure:
      fetchImportSourceTableSampleFailureReducer,
    resetImportSourceTest: resetImportSourceTestReducer,
    resetImportSourceTableSample: resetImportSourceTableSampleReducer,
  },
});

export const {
  fetchImportSourceVendors,
  fetchImportSourceVendorsSuccess,
  fetchImportSourceVendorsFailure,
  testImportSource,
  testImportSourceSuccess,
  testImportSourceFailure,
  createImportSource,
  createImportSourceSuccess,
  createImportSourceFailure,
  fetchImportSourceTables,
  fetchImportSourceTablesSuccess,
  fetchImportSourceTablesFailure,
  fetchImportSourceTableSample,
  fetchImportSourceTableSampleSuccess,
  fetchImportSourceTableSampleFailure,
  resetImportSourceTest,
  resetImportSourceTableSample,
} = importSourceSlice.actions;

export const selectImportSourceVendors = (state: RootState) =>
  state.importSource.vendors;
export const selectImportSourceTest = (state: RootState) =>
  state.importSource.sourceTest;
export const selectAvailableTables = (state: RootState) =>
  state.importSource.availableTables;
export const selectTableSample = (state: RootState) =>
  state.importSource.tableSample;

export function* importSourceSagas() {
  yield all([
    watchFetchImportSourceVendors(),
    watchTestImportSource(),
    watchFetchAvailableTables(),
    watchFetchImportSourceTableSample(),
    watchCreateImportSource(),
    watchCreateImportSourceSuccess(),
  ]);
}
export default importSourceSlice.reducer;
