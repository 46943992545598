import { DestinationVendor, ModelConfigColumn } from "@prequel/react";
import { getValidServiceAccountKey, ServiceAccountKey } from "../destination";

export type Vendor = DestinationVendor;

export type Source = {
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  host?: string;
  port?: string;
  database?: string;
  schema?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  bucket_access_id?: string;
  bucket_secret_key?: string;
  service_account_key?: string;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
  model_mappings: ModelMapping[];
};

export type ModelMapping = {
  model_name: string;
  source_table_name: string;
  description: string;
  mappings: ModelConfigColumn[];
};

export interface PreparedSource {
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  host?: string;
  port?: number;
  database?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  bucket_secret_key?: string;
  bucket_access_id?: string;
  service_account_key?: ServiceAccountKey;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: number;
  ssh_tunnel_username?: string;
}

export type AvailableTable = {
  table_name: string;
  schema: string;
};

export type SampleRow = {
  [key: string]: any;
};

export type TableSample = SampleRow[];

export const prepareSource: (s: Source) => PreparedSource = (source) => {
  const serviceAccountKey = getValidServiceAccountKey(
    source.service_account_key
  );

  const prepared: PreparedSource = {
    ...source,
    service_account_key: serviceAccountKey,
    port: parseInt(source.port || ""),
    ssh_tunnel_port: parseInt(source.ssh_tunnel_port || ""),
  };

  return prepared;
};
