import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { RootState, createWorkerSaga } from "..";
import { AppError } from "../../axios";

import {
  ImportMagicLink,
  MagicLink,
  MagicLinkArgs,
} from "../../store/magic_link";
import MagicLinkService from "../../store/magic_link/magic_link.service";

type MagicLinkState = {
  magicLink: MagicLink | undefined;
  importMagicLink: ImportMagicLink | undefined;
  isLoading: boolean;
};
const initialState: MagicLinkState = {
  magicLink: undefined,
  importMagicLink: undefined,
  isLoading: false,
};

const fetchMagicLinkReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<MagicLinkArgs>
> = (state) => {
  state.isLoading = true;
};

const fetchMagicLinkSuccessReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<MagicLink>
> = (state, action) => {
  state.magicLink = action.payload;
  state.isLoading = false;
};

const fetchMagicLinkFailureReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<AppError>
> = (state) => {
  state.isLoading = false;
};

const fetchImportMagicLinkReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<MagicLinkArgs>
> = (state) => {
  state.isLoading = false;
};

const fetchImportMagicLinkSuccessReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<ImportMagicLink>
> = (state, action) => {
  state.importMagicLink = action.payload;
  state.isLoading = false;
};

const fetchImportMagicLinkFailureReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<AppError>
> = (state) => {
  state.isLoading = false;
};

function* watchFetchMagicLink() {
  yield takeEvery(
    fetchMagicLink.type,
    createWorkerSaga(
      fetchMagicLink,
      fetchMagicLinkSuccess,
      fetchMagicLinkFailure,
      MagicLinkService.getMagicLink
    )
  );
}

function* watchFetchImportMagicLink() {
  yield takeEvery(
    fetchImportMagicLink.type,
    createWorkerSaga(
      fetchImportMagicLink,
      fetchImportMagicLinkSuccess,
      fetchImportMagicLinkFailure,
      MagicLinkService.getImportMagicLink
    )
  );
}

const magicLinkSlice = createSlice({
  name: "magicLink",
  initialState,
  reducers: {
    fetchMagicLink: fetchMagicLinkReducer,
    fetchMagicLinkSuccess: fetchMagicLinkSuccessReducer,
    fetchMagicLinkFailure: fetchMagicLinkFailureReducer,
    fetchImportMagicLink: fetchImportMagicLinkReducer,
    fetchImportMagicLinkSuccess: fetchImportMagicLinkSuccessReducer,
    fetchImportMagicLinkFailure: fetchImportMagicLinkFailureReducer,
  },
});

export const {
  fetchMagicLink,
  fetchMagicLinkSuccess,
  fetchMagicLinkFailure,
  fetchImportMagicLink,
  fetchImportMagicLinkSuccess,
  fetchImportMagicLinkFailure,
} = magicLinkSlice.actions;

// Selectors
export const selectIsLoading = (state: RootState) => state.magicLink.isLoading;
export const selectMagicLink = (state: RootState) => state.magicLink.magicLink;
export const selectImportMagicLink = (state: RootState) =>
  state.magicLink.importMagicLink;

export function* magicLinkSagas() {
  yield all([watchFetchMagicLink(), watchFetchImportMagicLink()]);
}

export default magicLinkSlice.reducer;
