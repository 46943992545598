import React, { useEffect, useMemo, useState } from "react";
import { Dropdown, DropdownListItem } from "@prequel-internal/react-components";

import { ReactComponent as SpinnerIcon } from "../../../assets/icons/spinner.svg";
import SampleImportSource from "../SampleImportSource";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  AvailableTable,
  PreparedSource,
  prepareSource,
  Source,
} from "../../../store/import_source";
import {
  fetchImportSourceTables,
  resetImportSourceTableSample,
  selectAvailableTables,
  selectTableSample,
} from "../../../store/import_source/import_source.duck";

const QUERY_METHODS = [
  { id: "table", title: "Table" },
  // MVP only includes table query
  // { id: "query", title: "Custom Query" },
];

type DropdownListItemWithTable = DropdownListItem<string> & {
  table: AvailableTable;
};

type QueryFormProps = {
  source: Source;
  orgId: string;
  linkId: string;
  isProd: boolean;
  canSubmit: React.Dispatch<React.SetStateAction<boolean>>;
};
const QueryForm = ({
  source,
  orgId,
  linkId,
  isProd,
  canSubmit,
}: QueryFormProps) => {
  const dispatch = useTypedDispatch();
  const availableTables = useTypedSelector(selectAvailableTables);
  const tableSample = useTypedSelector(selectTableSample);
  const [queryMethod, setQueryMethod] = useState<string>(QUERY_METHODS[0].id);
  const [selectedItem, setSelectedItem] = useState<DropdownListItemWithTable>({
    key: "",
    text: "",
    table: { schema: "", table_name: "" },
  });
  const [dropdownOptions, setDropdownOptions] = useState<
    DropdownListItemWithTable[]
  >([]);

  const preparedSource: PreparedSource = useMemo(
    () => prepareSource(source),
    [source]
  );

  useEffect(() => {
    dispatch(
      fetchImportSourceTables({
        magiclinkargs: {
          orgId,
          linkId,
          isProd,
        },
        source: preparedSource,
      })
    );
  }, []);

  useEffect(() => {
    canSubmit(tableSample.status === "success");
  }, [tableSample]);

  useEffect(() => {
    dispatch(resetImportSourceTableSample());
  }, [selectedItem]);

  useEffect(() => {
    if (availableTables.status === "success" && availableTables.tables) {
      const options = availableTables.tables.map((table: AvailableTable) => ({
        key: `${table.schema}.${table.table_name}`,
        text: `${table.schema}.${table.table_name}`,
        table,
      }));

      setSelectedItem(options[0]);
      setDropdownOptions(options);
    }
  }, [availableTables]);

  return (
    <section
      className="col-start-2 col-span-1"
      aria-labelledby="applicant-information-title"
    >
      <form
        // onSubmit={onSubmit}
        className="bg-white border border-gray-200 rounded-lg"
      >
        <div className="px-4 py-5">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Query Data
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Query the source to return relevant data.
          </p>
        </div>
        <div className="space-y-4 border-t border-gray-200 p-4">
          <label className="text-base font-medium text-gray-900">
            Query method
          </label>
          <fieldset className="mt-4 mb-2">
            <legend className="sr-only">Query method</legend>
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {QUERY_METHODS.map(({ id, title }) => (
                <div key={id} className="flex items-center">
                  <input
                    id={id}
                    name="notification-method"
                    type="radio"
                    onChange={() => setQueryMethod(id)}
                    defaultChecked={id === "table"}
                    className="h-4 w-4 border-gray-300 text-emerald-600 focus:ring-emerald-600"
                  />
                  <label
                    htmlFor={id}
                    className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                  >
                    {title}
                  </label>
                </div>
              ))}
            </div>
          </fieldset>
          {availableTables.status === "processing" && (
            <div className="flex align-items-center">
              <SpinnerIcon
                className="animate-spin h-5 w-5 mr-3 text-gray-500"
                viewBox="0 0 24 24"
              />
              <span className="text-gray-500 text-sm">Fetching tables...</span>
            </div>
          )}
          {availableTables.status === "success" && (
            <Dropdown
              items={dropdownOptions}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              className="w-64"
            />
          )}
          <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
          <SampleImportSource
            preparedSource={preparedSource}
            linkId={linkId}
            orgId={orgId}
            isProd={isProd}
            table={selectedItem.table}
          />
        </div>
      </form>
    </section>
  );
};

export default QueryForm;
