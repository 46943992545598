import React from "react";
import { DestinationVendor as Vendor } from "@prequel/react";

type BaseFormProps = {
  vendor: Vendor;
  docs?: string;
};
const BaseForm = ({ vendor, docs }: BaseFormProps) => {
  return (
    <div className="">
      <label
        htmlFor="description"
        className="block text-sm font-medium text-gray-700"
      >
        {`Configure your ${vendor.display_name} connection`}
      </label>
      <div className="mt-1">
        <p className="mt-1 text-sm text-gray-500">
          If you’re unsure how to get started or want to read about our
          recommended configuration,{" "}
          <a
            href={docs || vendor.docs}
            target="_blank"
            rel="noreferrer"
            className="font-medium text-primary-600 hover:text-primary-500"
          >
            {`view our documentation on ${vendor.display_name}.`}
          </a>
        </p>
      </div>
    </div>
  );
};

export default BaseForm;
