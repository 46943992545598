import axios, { ApiResponse, AppError, ErrorResponse } from "../../axios";
import {
  ImportMagicLink,
  MagicLink,
  MagicLinkArgs,
} from "../../store/magic_link";

const getMagicLink: (magiclinkargs: MagicLinkArgs) => Promise<MagicLink> = (
  magiclinkargs
) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .get(
      `public/orgs/${magiclinkargs.orgId}/${environment}/magic-links/${magiclinkargs.linkId}`
    )
    .then(
      (response: ApiResponse<{ magic_link: MagicLink }>) =>
        response.data.data.magic_link
    )
    .catch((reason: ErrorResponse) => {
      // Suppress the error alert for an expires/incorrect link -- that will be handled by a specific component instead
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch magic link.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getImportMagicLink: (
  magicLinkargs: MagicLinkArgs
) => Promise<ImportMagicLink> = (magiclinkargs) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .get(
      `public/orgs/${magiclinkargs.orgId}/${environment}/import/magic-links/${magiclinkargs.linkId}`
    )
    .then(
      (response: ApiResponse<{ magic_link: ImportMagicLink }>) =>
        response.data.data.magic_link
    )
    .catch((reason: ErrorResponse) => {
      // Suppress the error alert for an expires/incorrect link -- that will be handled by a specific component instead
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch import magic link.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const MagicLinkService = {
  getMagicLink,
  getImportMagicLink,
};
export default MagicLinkService;
